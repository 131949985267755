import { render, staticRenderFns } from "./c_player_registInfo.vue?vue&type=template&id=1c5cee7b&scoped=true&"
import script from "./c_player_registInfo.vue?vue&type=script&lang=js&"
export * from "./c_player_registInfo.vue?vue&type=script&lang=js&"
import style0 from "./c_player_registInfo.vue?vue&type=style&index=0&id=1c5cee7b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c5cee7b",
  null
  
)

export default component.exports